import { Catalog } from '@/types/Catalog';
import { getCatalogRegistration } from '@/redux/modules/catalog/registration/catalogRegistration.selectors';
import { GlobalState } from '@/redux/store';
import { pageSlicer } from '@/utils/pagination';
import { SearchSortAndDirection } from '@/types/search/enums/sortTypes';
import flow from 'lodash/fp/flow';
import orderBy from 'lodash/orderBy';
import type { ItemSummary } from '@/types/item/ItemSummary';

// Tab Filters
export const catalogDoneFilter = (catalog: any, tab: any) => {
    if (tab === 'ended') {
        return catalog.catalogStatus === 'done';
    }
    return catalog.catalogStatus !== 'done';
};

export const itemDoneFilter = (item: any, tab: any) => {
    if (tab === 'ended') {
        return item.catalogStatus === 'done';
    }
    return item.catalogStatus !== 'done';
};

export const catalogApprovedFilter = (state: any, item: Catalog, tab: string) => {
    const { approved, limited, pending } = getCatalogRegistration(state, item.catalogId);

    if (tab === 'approved') {
        return approved || limited;
    } else if (tab === 'pending') {
        return pending;
    }
    return false;
};

// Filter Functions
type FilterCatalogsByRegParams = {
    count?: number | 'all';
    page?: number;
    sort?: string;
    tab?: string;
    terms?: string;
};
export const filterCatalogsByReg = (
    state: GlobalState,
    all: Catalog[],
    { count, page, sort, tab, terms }: FilterCatalogsByRegParams = {}
) => {
    const loaded = filterEmptyCatalogs(all);
    const tabbed = tab ? loaded.filter((x) => catalogApprovedFilter(state, x, tab)) : loaded;
    const sorted = sortCatalogs(tabbed, sort);
    const filtered = filterCatalogSearch(sorted, terms);
    const items = pageSlicer(filtered, count, page) || [];

    return {
        catalogIds: items.map((item) => item.catalogId),
        filteredCount: filtered.length || 0,
        items,
        loadedItemsCount: loaded.length,
        tabbedCount: tabbed.length || 0,
        totalCount: all.length || 0,
    };
};

type FilterCatalogsParams = {
    count?: number | 'all';
    page?: number;
    sort?: string;
    tab?: string;
    terms?: string;
};
export const filterCatalogs = (all: any, { count, page, sort, tab, terms }: FilterCatalogsParams = {}) => {
    const loaded = filterEmptyCatalogs(all);
    const tabbed = tab ? loaded.filter((x) => catalogDoneFilter(x, tab)) : loaded;
    const sorted = sortCatalogs(tabbed, sort);
    const filtered = filterCatalogSearch(sorted, terms);
    const items = pageSlicer(filtered, count, page) || [];

    return {
        catalogIds: items.map((item) => item.catalogId),
        filteredCount: filtered.length || 0,
        items,
        loadedItemsCount: loaded.length,
        tabbedCount: tabbed.length || 0,
        totalCount: all.length || 0,
    };
};

export const myBidsStatusFilter = (itemWrapper: any, filter: string) => {
    if (filter === 'WON') {
        const { bidderHasHighBid, bidderWonItem, isSold } = itemWrapper;
        return bidderWonItem || (isSold && bidderHasHighBid);
    } else if (filter === 'LOST') {
        const { bidderHasHighBid } = itemWrapper;
        return !bidderHasHighBid;
    } else if (filter === 'LEADING') {
        const { bidderHasBid, bidderHasHighBid, isSold } = itemWrapper;
        return bidderHasBid && bidderHasHighBid && !isSold;
    } else if (filter === 'OUTBID') {
        const { bidderHasBid, bidderHasHighBid, isSold } = itemWrapper;
        return bidderHasBid && !bidderHasHighBid && !isSold;
    }
    return true;
};

type FilterItemsParams = {
    count?: number | 'all';
    filter?: string;
    page?: number;
    sort?: string;
    tab?: string;
    terms?: string;
};
export const filterItems = (all: any, { count, filter, page = 1, sort, tab, terms }: FilterItemsParams = {}) => {
    const loaded = filterEmptyItems(all);
    const tabbed = tab ? loaded.filter((item) => itemDoneFilter(item, tab)) : loaded;
    let filtered = filterItemSearch(tabbed, terms);
    filtered = filter ? filtered.filter((item) => myBidsStatusFilter(item, filter)) : filtered;
    const sorted = sortItems(filtered, sort);
    const items: ItemSummary[] = pageSlicer(sorted, count, page) || [];

    return {
        filteredCount: sorted.length || 0,
        itemIds: items.map((item) => item.itemId),
        items,
        loadedItemsCount: loaded.length,
        tabbedCount: tabbed.length || 0,
        totalCount: all.length || 0,
    };
};
type FilterSellersParams = {
    count?: number | 'all';
    page?: number;
    sort?: string;
    terms?: string;
};

export const filterSellers = (all: any, { count, page, sort, terms }: FilterSellersParams = {}) => {
    const loaded = filterEmptySellers(all);
    const sorted = sortSellers(loaded, sort);
    const filtered = filterSellerSearch(sorted, terms);

    return {
        filteredCount: filtered.length || 0,
        items: pageSlicer(filtered, count, page) || [],
        loadedItemsCount: loaded.length,
        totalCount: all.length || 0,
    };
};

// Search Filters
export const catalogSearchFilter = (catalog: any, searchString: string = '') => {
    const searchTokens = searchString.toLowerCase().trim().split(/\s/);
    const matchingTokens = searchTokens.filter((token) => {
        if (!catalog) {
            return false;
        }
        const { title = '' } = catalog;
        return `${title}`.toLowerCase().includes(token);
    });
    return matchingTokens.length === searchTokens.length;
};

export const itemSearchFilter = (item: any, searchString: string = '') => {
    const searchTokens = searchString.toLowerCase().trim().split(/\s/);
    const matchingTokens = searchTokens.filter((token) => {
        if (!item) {
            return false;
        }
        const { descriptionBlurb = '', lotNumber = '', sellerName, title = '' } = item;
        return `${descriptionBlurb} ${lotNumber} ${title} ${sellerName}`.toLowerCase().includes(token);
    });
    return matchingTokens.length === searchTokens.length;
};

export const sellerSearchFilter = (wrapper: any, searchString: string = '') => {
    const searchTokens = searchString.toLowerCase().trim().split(/\s/);
    const matchingTokens = searchTokens.filter((token) => {
        if (!wrapper || !wrapper.name) {
            return false;
        }
        return `${wrapper.name}`.toLowerCase().includes(token);
    });
    return matchingTokens.length === searchTokens.length;
};

export const filterEmptyCatalogs = (list?: Catalog[]) => {
    return list ? list.filter((x) => x?.catalogId) : [];
};

export const filterEmptyItems = (list?: any) => {
    return list ? list.filter((x) => Boolean(x.itemId)) : [];
};

export const filterEmptySellers = (list?: any) => {
    return list ? list.filter((x) => Boolean(x.sellerId)) : [];
};

export const filterCatalogSearch = (list?: any, terms?: string) => {
    if (!list) {
        return [];
    }
    return terms ? list.filter((x) => catalogSearchFilter(x, terms)) : list;
};

export const filterItemSearch = (list?: any, terms?: string) => {
    if (!list) {
        return [];
    }
    return terms ? list.filter((x) => itemSearchFilter(x, terms)) : list;
};

export const filterSellerSearch = (list?: any, terms?: string) => {
    if (!list) {
        return [];
    }
    return terms ? list.filter((x) => sellerSearchFilter(x, terms)) : list;
};

// Sorting Functions
const orderByBidderBidTimeAscending = (list: any) => {
    return orderBy(list, ['bidderBidTimestamp']);
};

const orderByBidderBidTimeDescending = (list: any) => {
    return orderBy(list, ['bidderBidTimestamp'], ['desc']);
};

export const orderByCatalogSaleStartAscending = (list: any) => {
    return orderBy(list, ['saleStartTs']);
};

const orderByCatalogSaleStartDescending = (list: any) => {
    return orderBy(list, ['saleStartTs'], ['desc']);
};

export const orderCatalogsByCatalogSaleStartAscending = (list: any) => {
    return orderBy(list, ['saleStartTs']);
};

const orderCatalogsByCatalogSaleStartDescending = (list: any) => {
    return orderBy(list, ['saleStartTs'], ['desc']);
};

const orderByCatalogTitleAscending = (list: any) => {
    return orderBy(list, [(x) => `${x?.title}`.toLowerCase()]);
};

const orderByItemAvailability = (list: any) => {
    return orderBy(list, ['isAvailable'], ['desc']);
};

const orderByItemBidCountAscending = (list: any) => {
    return orderBy(list, ['bidActivityCount']);
};

const orderByItemBidCountDescending = (list: any) => {
    return orderBy(list, ['bidActivityCount'], ['desc']);
};

const orderByItemHighBidEstimateDescending = (list: any) => {
    return orderBy(list, ['highBidEstimate'], ['desc']);
};

const orderByItemLowBidEstimateAscending = (list: any) => {
    return orderBy(list, ['lowBidEstimate']);
};

const orderByItemLotNumberAscending = (list: any) => {
    return orderBy(list, [(x) => (Number(x?.lotNumber) ? Number(x?.lotNumber) : `${x?.lotNumber}`.toLowerCase())]);
};

const orderByItemLotNumberDescending = (list: any) => {
    return orderBy(list, [(x) => `${x?.lotNumber}`.toLowerCase()], ['desc']);
};

const orderByItemTitleAscending = (list: any) => {
    return orderBy(list, [(x) => `${x?.title}`.toLowerCase()]);
};

const orderBySellerNameAscending = (list: any) => {
    return orderBy(list, [(x) => `${x?.name}`.toLowerCase()]);
};

const orderBySellerNameDescending = (list: any) => {
    return orderBy(list, [(x) => `${x?.name}`.toLowerCase()], ['desc']);
};

export const getPriceFromCard = (card: any) => {
    if (card.buyNowStatus === 1) {
        return card.buyNowPrice;
    }
    if (card.isPassed) {
        return 0;
    }
    if (card.isSold) {
        return card.salePrice;
    }
    return card.leadingBid || card.startPrice || 0;
};

const orderByItemPriceAscending = (list: any) => {
    return orderBy(list, [getPriceFromCard]);
};

const orderByItemPriceDescending = (list: any) => {
    return orderBy(list, [getPriceFromCard], ['desc']);
};

const orderByItemFeatured = (list: any) => {
    return orderBy(list, [SearchSortAndDirection.Promoted], ['desc']);
};

const orderBySaveSearchItemPublishTimeAscending = (list: any) => {
    return orderBy(list, ['savedSearch.publishTs']);
};

const orderBySaveSearchItemPublishTimeDescending = (list: any) => {
    return orderBy(list, ['savedSearch.publishTs'], ['desc']);
};

const orderByItemSaveTimeAscending = (list: any) => {
    return orderBy(list, ['savedItem.saveTs']);
};

const orderByItemSaveTimeDescending = (list: any) => {
    return orderBy(list, ['savedItem.saveTs'], ['desc']);
};

const orderByLotsAwayAscending = (list: any) => {
    return orderBy(list, ['lotsAway'], ['asc']);
};

const orderByUpcomingCatalogCountAscending = (list: any) => {
    return orderBy(list, ['catalogCounts.upcomingCount']);
};

const orderByUpcomingCatalogCountDescending = (list: any) => {
    return orderBy(list, ['catalogCounts.upcomingCount'], ['desc']);
};

const orderByViewedTimeAscending = (list: any) => {
    return orderBy(list, [SearchSortAndDirection.ViewedMostRecent]);
};

const orderByViewedTimeDescending = (list: any) => {
    return orderBy(list, [SearchSortAndDirection.ViewedMostRecent], ['desc']);
};

export const sortCatalogs = (all: any, sort?: string) => {
    let sorted;
    if (sort === 'DATE_ASCENDING') {
        sorted = flow(orderByCatalogTitleAscending, orderCatalogsByCatalogSaleStartAscending)(all);
    } else if (sort === 'DATE_DESCENDING') {
        sorted = flow(orderByCatalogTitleAscending, orderCatalogsByCatalogSaleStartDescending)(all);
    } else {
        sorted = all;
    }
    return sorted;
};

export const sortItems = (all: any, sort?: string) => {
    let sorted;
    if (sort === 'DATE_ASCENDING') {
        sorted = flow(orderByItemLotNumberAscending, orderByCatalogSaleStartAscending)(all);
    } else if (sort === 'BID_OLDEST_FIRST') {
        sorted = flow(orderByItemTitleAscending, orderByBidderBidTimeAscending)(all);
    } else if (sort === 'BID_RECENT_FIRST') {
        sorted = flow(orderByItemTitleAscending, orderByBidderBidTimeDescending)(all);
    } else if (sort === 'DATE_DESCENDING') {
        sorted = flow(orderByItemTitleAscending, orderByCatalogSaleStartDescending)(all);
    } else if (sort === 'ESTIMATE_HIGHEST_FIRST') {
        sorted = flow(orderByItemTitleAscending, orderByItemHighBidEstimateDescending)(all);
    } else if (sort === 'ESTIMATE_LOWEST_FIRST') {
        sorted = flow(orderByItemTitleAscending, orderByItemLowBidEstimateAscending)(all);
    } else if (sort === 'FEATURED_LOTS') {
        sorted = flow(orderByItemBidCountDescending, orderByItemFeatured)(all);
    } else if (sort === 'LEAST_BIDS') {
        sorted = flow(orderByItemTitleAscending, orderByItemBidCountAscending)(all);
    } else if (sort === 'LOT_NUMBER_LOWEST_FIRST') {
        sorted = flow(orderByItemTitleAscending, orderByItemLotNumberAscending)(all);
    } else if (sort === 'LOT_NUMBER_HIGHEST_FIRST') {
        sorted = flow(orderByItemTitleAscending, orderByItemLotNumberDescending)(all);
    } else if (sort === 'MOST_BIDS') {
        sorted = flow(orderByItemTitleAscending, orderByItemBidCountDescending)(all);
    } else if (sort === 'PRICE_HIGHEST_FIRST') {
        sorted = flow(orderByItemTitleAscending, orderByItemPriceDescending)(all);
    } else if (sort === 'PRICE_LOWEST_FIRST') {
        sorted = flow(orderByItemTitleAscending, orderByItemPriceAscending)(all);
    } else if (sort === 'RESULT_OLDEST_FIRST') {
        sorted = flow(orderByItemTitleAscending, orderBySaveSearchItemPublishTimeAscending)(all);
    } else if (sort === 'RESULT_RECENT_FIRST') {
        sorted = flow(orderByItemTitleAscending, orderBySaveSearchItemPublishTimeDescending)(all);
    } else if (sort === 'SAVED_OLDEST_FIRST') {
        sorted = flow(orderByItemTitleAscending, orderByItemSaveTimeAscending)(all);
    } else if (sort === 'SAVED_RECENT_FIRST') {
        sorted = flow(orderByItemTitleAscending, orderByItemSaveTimeDescending)(all);
    } else if (sort === 'TIME_ENDING_FURTHEST') {
        sorted = flow(orderByItemLotNumberDescending, orderByCatalogSaleStartDescending, orderByItemAvailability)(all);
    } else if (sort === 'TIME_SOONEST_FIRST') {
        sorted = flow(orderByItemLotNumberAscending, orderByCatalogSaleStartAscending, orderByItemAvailability)(all);
    } else if (sort === 'LOTS_AWAY_FIRST') {
        sorted = flow(orderByLotsAwayAscending)(all);
    } else if (sort === 'VIEWED_OLDEST_FIRST') {
        sorted = flow(orderByItemTitleAscending, orderByViewedTimeAscending)(all);
    } else if (sort === 'VIEWED_RECENT_FIRST') {
        sorted = flow(orderByItemTitleAscending, orderByViewedTimeDescending)(all);
    } else {
        sorted = all;
    }
    return sorted || [];
};

export const sortSellers = (all: any, sort?: string) => {
    let sorted;
    if (sort === 'UPCOMING_HIGHEST_FIRST') {
        sorted = flow(orderBySellerNameAscending, orderByUpcomingCatalogCountDescending)(all);
    } else if (sort === 'UPCOMING_LOWEST_FIRST') {
        sorted = flow(orderBySellerNameAscending, orderByUpcomingCatalogCountAscending)(all);
    } else if (sort === 'NAME_HIGHEST_FIRST') {
        sorted = flow(orderBySellerNameDescending)(all);
    } else if (sort === 'NAME_LOWEST_FIRST') {
        sorted = flow(orderBySellerNameAscending)(all);
    } else {
        sorted = all;
    }
    return sorted;
};
